import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { Feedback } from '@interfaces';
import { FuncService } from '../../services';
import { getLabels } from './feedback.page.labels';


@Component({
	selector: 'feedback-page',
	imports: [
		MobilePagePart,
		FormsModule,
	],
	templateUrl: './feedback.page.html'
})

export class FeedbackPage implements OnInit {
	private frame = inject(MobileFrameService);
	private func = inject(FuncService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);

	public feedback = signal<Feedback[]>([]);
	public selectedFeedback = signal<Feedback | undefined>(undefined);


	async ngOnInit() {
		const feedback = await this.func.applications.getFeedback();
		this.feedback.set(feedback);

		this.frame.setUrlMetadata({
			url: `/feedback`,
			backUrl: `/`,
			pageName: computed(() => this.labels.common.Feedback()),
			headerText: computed(() => this.labels.common.Feedback()),
		});

		for (const data of this.feedback()) {
			const pageName = `Feedback-${data.siteName}-${data.programInstanceName}-${data.programName}`;
			const url = `/feedback?key=${data.key}`;
			this.frame.setUrlMetadata({
				url,
				backUrl: `/`,
				pageName: computed(() => pageName),
				headerText: computed(() => this.labels.common.Feedback()),
			});
		}


		const key: string = this.route.snapshot.queryParams['key'];
		if (key) {
			this.setSelectedFeedback(key);
		}

	}


	onChange(value: string) {
		this.router.navigate([this.urlService.withCurrentLanguage('feedback')], { queryParams: { key: value } });
		this.setSelectedFeedback(value);
	}

	setSelectedFeedback(key: string) {
		const feedback = this.feedback().find(pgm => pgm.key == key);
		this.selectedFeedback.set(feedback);
	}

}