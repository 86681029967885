import { Component, computed, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AddressData, CommonAddressField, CommonAddressFieldConfig, CommonAddressFieldWidget, CommonDateTimeField, CommonDateTimeFieldConfig, CommonDateTimeFieldWidget, CommonEmailField, CommonEmailFieldWidget, CommonGeneralFieldConfig, CommonMultiSelectField, CommonMultiselectFieldWidget, CommonNumberField, CommonNumberFieldConfig, CommonNumberFieldWidget, CommonOptionsFieldConfig, CommonPhoneField, CommonPhoneFieldWidget, CommonProgressBarWidget, CommonRadioField, CommonRadioFieldWidget, CommonSelectField, CommonSelectFieldWidget, CommonSmileyRatingWidget, CommonTextField, CommonTextFieldWidget, CommonThumbsUpDownRatingWidget, CommonUrlField, CommonUrlFieldWidget, Option, ThumbsUpDown } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
export interface GooglePlace {
	streetAddress: string,
	placeAddress: string,
	inUS: boolean,
	zipId: number | undefined,
	placeId: string | undefined,
}


@Component({
	selector: 'fields-page',
	imports: [
		MobilePagePart,
		FormsModule,
		CommonSelectFieldWidget,
		CommonMultiselectFieldWidget,
		CommonDateTimeFieldWidget,
		CommonPhoneFieldWidget,
		CommonTextFieldWidget,
		CommonNumberFieldWidget,
		CommonEmailFieldWidget,
		CommonUrlFieldWidget,
		CommonAddressFieldWidget,
		CommonRadioFieldWidget,
		CommonProgressBarWidget,
		CommonSmileyRatingWidget,
		CommonThumbsUpDownRatingWidget,
	],
	templateUrl: './fields.page.html'
})

export class ExampleFieldsPage {

	public progressBar = [true, false, true, false, true, true];

	public initialRating = signal(1);
	public thumbsUpDownRating = signal<ThumbsUpDown>({ text: `Was this content helpful?`, rating: undefined });

	readonly number: CommonNumberField = {
		config: signal<CommonNumberFieldConfig>({ label: 'Number', required: false, isSpanish: false, type: 'numeric', }),
		actualValue: computed(() => undefined),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			console.log('Number', value);
		},
		error: signal(''),
	};
	readonly dollar: CommonNumberField = {
		config: signal<CommonNumberFieldConfig>({ label: 'Dollar', max: 10000, required: false, isSpanish: false, type: 'dollar', }),
		actualValue: computed(() => undefined),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			console.log('Dollar', value);
		},
		error: signal(''),
	};
	readonly percentage: CommonNumberField = {
		config: signal<CommonNumberFieldConfig>({ label: 'Percentage', required: false, isSpanish: false, type: 'percentage', }),
		actualValue: computed(() => undefined),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			console.log('Percentage', value);
		},
		error: signal(''),
	};

	readonly date: CommonDateTimeField = {
		config: signal<CommonDateTimeFieldConfig>({ label: 'Birthday', required: false, isSpanish: false, type: 'birthday', includeTime: false, }),
		actualValue: computed(() => Date.now() / 1000),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			console.log('Date', value);
		},
		error: signal(''),
	};

	readonly dateTime: CommonDateTimeField = {
		config: signal<CommonDateTimeFieldConfig>({ label: 'DateTime', required: false, isSpanish: false, type: 'event', includeTime: true, }),
		actualValue: computed(() => Date.now() / 1000),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			console.log('DateTime', value);
		},
		error: signal(''),
	};


	private readonly racesOptions: Option<string>[] = [
		{ value: 'N', text: 'American Indian or Alaska Native' },
		{ value: 'A', text: 'Asian' },
		{ value: 'B', text: 'Black or African American' },
		{ value: 'P', text: 'Native Hawaiian or Other Pacific Islander' },
		{ value: 'W', text: 'White' },
		{ value: 'O', text: 'Other' },
	];

	public readonly races: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Race', options: this.racesOptions, required: false, isSpanish: false }),
		actualValue: computed(() => 'A'),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			console.log('Race', value);
		},
		error: signal(''),
	};


	readonly phone: CommonPhoneField = {
		config: signal<CommonGeneralFieldConfig>({ label: 'Phone', required: false, isSpanish: false }),
		actualValue: computed(() => '+18579916615'),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			console.log('Phone', value);
		},
		error: signal(''),
	};

	readonly url: CommonUrlField = {
		config: signal<CommonGeneralFieldConfig>({ label: 'Url', required: false, isSpanish: false }),
		actualValue: computed(() => ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			console.log('URL', value);
		},
		error: signal(''),
	};

	readonly email: CommonEmailField = {
		config: signal<CommonGeneralFieldConfig>({ label: 'Email', required: false, isSpanish: false }),
		actualValue: computed(() => ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			console.log('Email', value);
		},
		error: signal(''),
	};


	private radioButton = signal('A');

	public readonly racesRadio: CommonRadioField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Race Radio', options: this.racesOptions, required: false, isSpanish: false }),
		actualValue: computed(() => this.radioButton()),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			console.log('Race Radio', value);
			this.radioButton.set(value);
		},
		error: signal(''),
	};

	public readonly racesMultiple: CommonMultiSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Race Multiple', options: this.racesOptions, required: false, isSpanish: false }),
		actualValue: computed(() => ['A', 'W']),
		pendingValue: signal<string[] | null>(null),
		saving: signal(false),
		save: async (value: string[]) => {
			console.log('Races', value);
		},
		error: signal(''),
	};


	readonly textarea: CommonTextField = {
		config: signal({ label: 'Textarea', min: 0, max: 200, fixCase: true, multiLine: true, isSpanish: false }),
		actualValue: computed(() => 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Obcaecati possimus illum maxime, velit totam rem. Cum dolores illo deleniti totam quos repellat. Magni modi corporis quaerat similique eaque consectetur eligendi?'),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			console.log('TextArea', value);
		},
		error: signal(''),
	};

	readonly text: CommonTextField = {
		config: signal({ label: 'Text', min: 0, max: 100, fixCase: true, multiLine: false, isSpanish: false }),
		actualValue: computed(() => 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.'),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			console.log('Text', value);
		},
		error: signal(''),
	};

	readonly address: CommonAddressField = {
		config: signal<CommonAddressFieldConfig>({ label: 'Home Address', isSpanish: false, required: false }),
		actualValue: computed(() => ({ inUS: true, latitude: 4265, longitude: -7132, placeAddress: "175 Cabot St, Lowell, MA 01854, USA", placeId: "ChIJw9d3aEak44kRnMFh-iLd2E8", streetAddress: "175 Cabot St", zipId: 1854, })),
		pendingValue: signal<AddressData | undefined | null>(null),
		saving: signal(false),
		save: async (value: AddressData | undefined) => {
			console.log('Address', value);
		},
		error: signal(''),
	};

	private frame = inject(MobileFrameService);

	constructor() {
		this.frame.setUrlMetadata({
			url: '/examples/fields',
			backUrl: '/examples',
			pageName: computed(() => `Fields`),
			headerText: computed(() => `Fields`),
		});
	}


	setInitialRating(value: number) {
		this.initialRating.set(value);
	}

	setThumbsUpDownRating(value: 1 | -1 | undefined) {
		this.thumbsUpDownRating.set({ ...this.thumbsUpDownRating(), rating: value });
	}

}