import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonIconTextWidget } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService } from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationService } from '../../application.service';
import { getLabels } from './make-changes-flyout.part.labels';


@Component({
	selector: 'application-make-changes-flyout-part',
	imports: [
		CommonIconTextWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		RouterModule,
	],
	templateUrl: './make-changes-flyout.part.html',
	styleUrl: './make-changes-flyout.part.scss'
})
export class ApplicationMakeChangesFlyoutPart {
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	public frame = inject(MobileFrameService);
	public applicationService = inject(ApplicationService);
	public labels = getLabels(this.applicationService.urlService);


	close() {
		this.activeOffcanvas.close();
	}


	public async withdraw() {
		await this.applicationService.withdraw();
		this.close();
	}

	public async reopen() {
		await this.applicationService.reopen();
		this.close();
	}
}