import { NgClass } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonButtonColumnWidget, CommonButtonRowWidget, CommonEmailFieldWidget, CommonMessageWidget, CommonTextFieldWidget } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { ApplicationService } from '../application.service';
import { ApplicationYourTeamFormService } from './your-team-form.service';


@Component({
	selector: 'application-your-team-page',
	imports: [
		MobilePagePart,
		CommonButtonColumnWidget,
		CommonButtonRowWidget,
		CommonMessageWidget,
		FormsModule,
		NgClass,
		MobileListWidget,
		CommonTextFieldWidget,
		CommonEmailFieldWidget
	],
	templateUrl: './your-team.page.html'
})

export class ApplicationYourTeamPage implements OnInit {
	private frame = inject(MobileFrameService);
	public applicationService = inject(ApplicationService);
	private route = inject(ActivatedRoute);
	public form = inject(ApplicationYourTeamFormService);

	public teamHeader = computed(() => {
		const participants = this.applicationService.application()?.participants || [];
		if (participants.length > 1) return this.form.labels.Applicant() + ` ${participants.length - 1}`;
		else return this.form.labels.ApplicantOnly();
	});



	instructionClicked = signal(false);

	public primaryBtn = computed<'next-step' | 'invite-participant' | 'instructions' | 'none'>(() => {
		if (this.showNextStep()) {
			return 'next-step';
		}

		if (this.form.showInviteForm()) {
			return 'invite-participant';
		}


		const isClicked = this.instructionClicked();
		if (!isClicked) return 'instructions';
		return 'none';
	});




	public teamSize = signal({ value: '', text: '' });
	public teamSizeOptions = [{ value: 'S', text: this.form.labels.ApplicantOnly() }, { value: 'M', text: this.form.labels.ApplicantOtherTeamMembers() }];



	public hasOrInviteParticipants = signal('');

	public participantsList = computed<MobileListWithSubItem[]>(() => {
		const invitedParticipants = this.applicationService.invitedParticipants();
		return invitedParticipants.map(participant => ({
			icon: participant.status === 'Complete' ? 'fa-circle-check color-green' : 'fa-circle color-lightgray',
			iconStyle: 'fa-solid',
			text: `${participant.firstName} ${participant.lastName}`,
			callback: () => {
				this.applicationService.flyoutService.showApplicationParticipant(participant);
			},
			subText: participant.status,
		}));
	});


	public showNextStep = computed(() => {
		const application = this.applicationService.application();
		return application &&
			(this.teamSize().value === 'S'
				|| (
					this.teamSize().value === 'M'
					&& !this.form.restOfTeam().error()
					&& (
						this.hasOrInviteParticipants() == 'N'
						||
						(this.hasOrInviteParticipants() == 'Y' && this.applicationService.invitedParticipants().length)
					)
					&& !this.form.showInviteForm()

				)
			);
	});

	public disableTeamSize = computed(() => {
		return this.applicationService.invitedParticipants().length > 0
			|| (this.applicationService.application()?.application.restOfTeam || 'N/A').toLowerCase() !== 'N/A'.toLowerCase()
	});



	constructor() {
		this.applicationService.setApplicationIdAndQuestion(+this.route.snapshot.params['applicationId'], undefined);
		this.frame.setUrlMetadata({
			url: `/applications/${this.applicationService.applicationId()}/your-team`,
			backUrl: `/applications/${this.applicationService.applicationId()}`,
			pageName: computed(() => this.form.labels.YourTeam()),
			headerText: computed(() => this.form.labels.YourTeam()),
		});
	}

	ngOnInit(): void {
		const application = this.applicationService.application();
		if (application) {
			if (this.applicationService.invitedParticipants().length > 0) {
				this.teamSize.set({ value: 'M', text: this.form.labels.ApplicantOtherTeamMembers() });
			}
			else if (application.application.restOfTeam) {
				if (application.application.restOfTeam.toLowerCase() !== 'N/A'.toLowerCase()) {
					this.teamSize.set({ value: 'M', text: this.form.labels.ApplicantOtherTeamMembers() });
				}
				else {
					this.teamSize.set({ value: 'S', text: this.form.labels.ApplicantOnly() });
				}
			}
			else {
				this.teamSize.set({ value: '', text: '' });
			}


			if (this.applicationService.invitedParticipants().length > 0) {
				this.hasOrInviteParticipants.set('Y');
			}

			const key = `application-${application.application.applicationId}-your-team`;
			const isClicked = this.applicationService.getInstructionKey(key);
			this.instructionClicked.set(isClicked);
		}
	}


	onChange(value: string) {
		if (value == 'M') this.teamSize.set({ value: 'M', text: this.form.labels.ApplicantOtherTeamMembers() });
		else {
			this.teamSize.set({ value: 'S', text: this.form.labels.ApplicantOnly() });
		}
	}



	openInstructions() {
		const applicationId = this.applicationService.applicationId();
		if (applicationId) {
			const key = `application-${applicationId}-your-team`;

			const stored = this.applicationService.getInstructionKey(key);
			if (!stored) {
				this.applicationService.setInstructionKey(key, 'clicked');
				this.instructionClicked.set(true);
			}
			this.applicationService.openInstructionFlyout(this.form.labels.AddMemberMessage());
		}

	}

}