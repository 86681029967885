import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget, CommonNetPromoterScoreWidget, SpinnerService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';


@Component({
	selector: 'examples-page',
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget,
		CommonNetPromoterScoreWidget,
	],
	templateUrl: './examples.page.html'
})

export class ExamplesPage {

	private frame = inject(MobileFrameService);
	private spinner = inject(SpinnerService);

	constructor() {
		this.frame.setUrlMetadata({
			url: '/examples',
			backUrl: '/',
			pageName: computed(() => `Examples`),
			headerText: computed(() => `Examples`),
		});
	}


	endlessSpinner() {
		this.spinner.addSpinner('Spinning', 'Spinning');
	}

	updateValue(value: { key: string, value: number }) {
		console.log(value);
	}

}