import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Attempt: computed(() => en() ? 'Attempt' : 'Intento'),
		ClickHereToReadWhy: computed(() => en() ? 'Click here to read why' : 'Haz clic aquí para leer por qué'),
		Correct: computed(() => en() ? 'Correct' : 'Correcto'),
		CorrectChoice: computed(() => en() ? 'Correct Choice' : 'Opción correcta'),
		Incorrect: computed(() => en() ? 'Incorrect' : 'Incorrecto'),
		NeedToReviewGoBackToOverview: computed(() => en() ? 'Need to review? Go back to Overview' : '¿Necesitas revisarlo? Vuelve al resumen.'),
		Of: computed(() => en() ? 'of' : 'de'),
		PercentCorrectMsg: computed(() => en() ? 'You got {percentCorrect}% correct.' : 'Obtuviste {percentCorrect}% correcto.'),
		Reason: computed(() => en() ? 'Reason' : 'Razón'),
		TryAgain: computed(() => en() ? 'Try again' : 'Trata de nuevo'),
	};
}