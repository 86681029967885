import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { ThumbsUpDownRating } from './thumbs-up-down-rating';


@Component({
	selector: 'common-thumbs-up-down-rating',
	imports: [NgClass,],
	templateUrl: './common-thumbs-up-down-rating.widget.html',
	styleUrls: ['./common-thumbs-up-down-rating.widget.scss']
})
export class CommonThumbsUpDownRatingWidget {

	public rating = input.required<{ text: string, rating: ThumbsUpDownRating }>();
	public updateRating = output<ThumbsUpDownRating>();



	public toggleRating(type: 'up' | 'down') {
		const initialRating: ThumbsUpDownRating = this.rating().rating;
		let rating: 1 | -1 | undefined;

		if (type === 'up') {
			rating = initialRating === 1 ? undefined : 1;
		} else {
			rating = initialRating === -1 ? undefined : -1;
		}

		this.updateRating.emit(rating);
	}

}