import { NgClass } from '@angular/common';
import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { CommonAddressFieldWidget, CommonButtonColumnWidget, CommonDateTimeFieldWidget, CommonMultiselectFieldWidget, CommonNumberFieldWidget, CommonPhoneFieldWidget, CommonSelectFieldWidget, CommonTextFieldWidget } from '@eforall/common';
import { ApplicationAboutYouFormService } from './about-you-form.service';
import { getLabels } from './application-about-you.part.labels';


@Component({
	selector: 'application-about-you-part',
	imports: [
		CommonTextFieldWidget,
		CommonSelectFieldWidget,
		CommonNumberFieldWidget,
		CommonPhoneFieldWidget,
		CommonDateTimeFieldWidget,
		CommonMultiselectFieldWidget,
		CommonAddressFieldWidget,
		NgClass,
		CommonButtonColumnWidget,
	],
	templateUrl: './application-about-you.part.html'
})

export class ApplicationAboutYouPart implements OnInit {
	public form = inject(ApplicationAboutYouFormService);
	public labels = getLabels(this.form.urlService);


	public readonly showOtherGender = computed(() => {
		if (this.form.gender().actualValue() === 'O') return true;
		else return false;
	});

	public instructions = computed(() => this.labels.InstructionMsg());
	private instructionClicked = signal(false);

	public primaryBtn = computed<'next-step' | 'instructions' | 'none'>(() => {
		if (this.form.aboutYouComplete()) {
			return 'next-step';
		}

		const isClicked = this.instructionClicked();
		if (!isClicked) return 'instructions';
		return 'none';
	});


	ngOnInit() {
		const applicationId = this.form.applicationService.applicationId();
		const key = `application-${applicationId}-about-you`;
		const isClicked = this.form.applicationService.getInstructionKey(key);
		this.instructionClicked.set(isClicked);
	}

	openInstructions() {
		const applicationId = this.form.applicationService.applicationId();
		if (applicationId) {
			const key = `application-${applicationId}-about-you`;

			const stored = this.form.applicationService.getInstructionKey(key);
			if (!stored) {
				this.form.applicationService.setInstructionKey(key, 'clicked');
				this.instructionClicked.set(true);
			}
			this.form.applicationService.openInstructionFlyout(this.instructions());
		}

	}
}