import { Component, HostListener, inject } from '@angular/core';
import { CommonSpinnerWidget, SpinnerService } from '@eforall/common';


@Component({
	selector: 'mobile-spinner',
	imports: [CommonSpinnerWidget],
	templateUrl: './mobile-spinner.widget.html',
	styleUrl: './mobile-spinner.widget.scss'
})


export class MobileSpinnerWidget {

	public spinnerService = inject(SpinnerService);

	@HostListener('click', ['$event'])
	backClick(e: Event) {
		console.log(e);
		e.preventDefault();
		e.stopPropagation();
		return false;
	}

}


