import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		ClickMeCourseViewMsg: computed(() => en() ? `Click me to go to course view` : `Haz clic en mí para ir a la vista del curso`),
		Courses: computed(() => en() ? `Courses` : `Cursos`),
	};
}