<mobile-page-part>
	<div class="info-content">
		<h3>{{levelDetails()?.infoContent() || labels.NoDataLoaded()}}</h3>
	</div>

	@if(levelDetails(); as levelDetails){
	<div class="white-box">
		<h4>{{levelDetails.name}}</h4>
		<div>{{levelDetails.description}}</div>
	</div>


	<mobile-list [items]="levelDetails.activities" [header]="labels.Activities()" [height]="80"></mobile-list>


	@if(levelDetails.children){
	<div>
		<h4>{{levelDetails.childrenType}}</h4>
		<div ngbAccordion [closeOthers]="true">
			@for(child of levelDetails.children; track child.courseLevelStructureId; let idx3 = $index;){
			<div ngbAccordionItem>
				<div ngbAccordionHeader>
					<button ngbAccordionButton class="btn btn-flat p-2">
						<div class="d-flex flex-column">
							<div>{{child.name}}</div>
							<small class="color-gray">{{child.subText}}</small>
						</div>
					</button>
				</div>

				<div ngbAccordionCollapse>
					<div ngbAccordionBody class="p-2 d-flex flex-column gap-2">
						<ng-template>
							<div>{{child.description}}</div>

							@if(child.additionalDescription){
							<div>
								<div>{{child.additionalDescription.description}}</div>
								<ul>
									@for(level of child.additionalDescription.levels; track $index){
									<li>
										{{level}}
									</li>
									}
								</ul>
							</div>
							}

							@if(child.progress){
							<div>
								<div>{{child.progress.text}}</div>
								<common-progress-bar [percentage]="child.progress.percentage"></common-progress-bar>
							</div>
							}


							@if(child.courseLevelStructureId){
							<common-button-row [center]="true">
								<button class="btn btn-large btn-primary"
									routerLink="../../level/{{child.courseLevelStructureId}}">
									{{labels.button.View()}}
								</button>
							</common-button-row>
							}


						</ng-template>
					</div>
				</div>
			</div>
			}

		</div>
	</div>
	}

	<common-button-row [center]="true">
		<button class="btn btn-primary btn-large" [routerLink]="levelDetails.nextLevelUrl">{{labels.button.Next()}}</button>
	</common-button-row>
	}
	@else {
	<common-message alertType="warning">{{labels.NotEnrolledMsg()}}</common-message>
	}


</mobile-page-part>