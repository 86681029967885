import { Component, computed, inject } from '@angular/core';
import { UrlService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { getLabels } from './bios.page.labels';


@Component({
	selector: 'profile-bios-page',
	imports: [
		MobilePagePart,
	],
	templateUrl: './bios.page.html'
})

export class ProfileBiosPage {
	private frame = inject(MobileFrameService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);


	constructor() {
		this.frame.setUrlMetadata({
			url: '/profile/bios',
			backUrl: '/profile',
			pageName: computed(() => this.labels.Bios()),
			headerText: computed(() => this.labels.Bios()),
		});
	}

}