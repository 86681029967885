import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService, CommonSignedOutWidget, EnvironmentService, ErrorService, SpinnerService, UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileLayoutService, MobileSpinnerWidget } from '@eforall/mobile';
import { NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments';
import { AppService, DomainDataService, FlyoutService, FuncService } from '../../services';
import { getLabels } from './bootstrap.page.labels';
import { getMobileFrameConfig } from './get-mobile-frame-config';


@Component({
	selector: 'bootstrap-page',
	imports: [
		CommonSignedOutWidget,
		MobileSpinnerWidget,
		NgbModule,
		RouterOutlet,
		JsonPipe,
	],
	templateUrl: `bootstrap.page.html`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BootstrapPage {

	public spinnerService = inject(SpinnerService);
	private env = inject(EnvironmentService);
	public auth = inject(AuthService);
	private router = inject(Router);
	private util = inject(UtilityService);
	public errorService = inject(ErrorService);
	private frame = inject(MobileFrameService);
	private layout = inject(MobileLayoutService);
	private func = inject(FuncService);
	private ngbTooltipConfig = inject(NgbTooltipConfig);
	private urlService = inject(UrlService);
	private labels = getLabels(this.urlService);
	// private markdownOverrideService = inject(MarkdownOverrideService);
	// private pwa = inject(PwaService);

	public app = inject(AppService);
	public domainData = inject(DomainDataService);
	private flyoutService = inject(FlyoutService);


	constructor() {

		this.auth.setData(
			this.app.loadData.bind(this.app),
			this.app.clearData.bind(this.app),
			this.domainData.loadDomainData.bind(this.domainData),
			this.domainData.clearDomainData.bind(this.domainData),
		);

		this.env.setEnvironment(environment);

		this.init();
		this.setNgbTooltipConfig();
	}

	init() {
		this.frame.initialize(getMobileFrameConfig(this.app, this.flyoutService, this.urlService));
	}

	setNgbTooltipConfig() {
		this.ngbTooltipConfig.triggers = 'hover';
		this.ngbTooltipConfig.openDelay = 2000;
		this.ngbTooltipConfig.closeDelay = 100;
	}
}
