import { Component, computed, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonButtonRowWidget, CommonTextField, CommonTextFieldConfig, UrlService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { FuncService } from '../../../services';
import { getLabels } from './join.page.labels';


@Component({
	selector: 'applications-join-page',
	imports: [
		MobilePagePart,
		CommonButtonRowWidget,
		FormsModule,
	],
	templateUrl: './join.page.html'
})

export class ApplicationJoinPage {
	private frame = inject(MobileFrameService);
	private func = inject(FuncService);
	private urlService = inject(UrlService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	public labels = getLabels(this.urlService);


	readonly code: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: this.labels.Code(), min: 9, max: 9, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async () => {
		},
		error: signal(''),
	};

	public pendingCode = signal<string>('');


	private readonly validated = signal(false);
	public readonly errorToShow = computed(() => {
		if (this.validated() && this.code.error()) return this.code.error();
		return '';
	});


	constructor() {
		this.frame.setUrlMetadata({
			url: '/applications/join',
			backUrl: '/applications',
			pageName: computed(() => this.labels.Join()),
			headerText: computed(() => this.labels.Join()),
		});
	}

	onChange(value: string) {
		this.pendingCode.set(value);
		this.validated.set(false);
	}

	async validate() {
		this.validated.set(true);
		let error = this.checkForError();
		if (!error) {
			const code = this.pendingCode().split('-').join('');
			const codeDetails = await this.func.pendingParticipant.validateInviteCode({ code });
			if (!codeDetails) error = this.labels.NotValid().split('{{pendingCode}}').join(this.pendingCode());
		}

		this.code.error.set(error);
		if (!error) this.router.navigate([this.pendingCode().toLowerCase()], { relativeTo: this.route })
	}

	checkForError(): string {
		const value = this.pendingCode();
		const pattern = /^[a-zA-Z]{3}-[a-zA-Z]{3}-[a-zA-Z]{3}$/;

		if (value.length == 0) return this.labels.MissingRequired();
		if (value.length && !value.match(pattern)) return this.labels.InvalidPattern();
		if (value.length < 11) return this.labels.MinLength();
		return '';
	}


}