import { Component, computed, inject, OnInit } from '@angular/core';
import { UrlService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobilePagePart } from '@eforall/mobile';
import { getLabels } from './calendar.page.labels';
import { CalendarService } from './calendar.service';


@Component({
	selector: 'calendar-page',
	imports: [
		MobilePagePart,
		MobileListWidget
	],
	templateUrl: './calendar.page.html'
})

export class CalendarPage implements OnInit {

	private frame = inject(MobileFrameService);
	public calendarService = inject(CalendarService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);

	constructor() {
		this.frame.setUrlMetadata({
			url: `/calendar`,
			backUrl: `/`,
			pageName: computed(() => `Calendar`),
			headerText: computed(() => `Calendar`),
		});
	}


	async ngOnInit() {
		await this.calendarService.loadData()
	}

}