import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonButtonRowWidget, CommonMessageWidget, UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { AccSessionSurveyAccess, AccSessionSurveyAnswers, AccSessionSurveyTopicAnswer, AccSessionTopicWithSpecialists, SurveyField, TopicTypeId } from '@interfaces';
import { FlyoutService, FuncService } from '../../../services';
import { SurveyFieldsDisplayPart } from '../fields-display/fields-display.part';
import { getLabels } from './acc-session-survey.page.labels';


@Component({
	selector: 'acc-session-survey-page',
	imports: [
		MobilePagePart,
		CommonMessageWidget,
		SurveyFieldsDisplayPart,
		CommonButtonRowWidget,
	],
	templateUrl: './acc-session-survey.page.html'
})

export class SurveyAccSessionPage implements OnInit {

	private frame = inject(MobileFrameService);
	private route = inject(ActivatedRoute);
	private func = inject(FuncService);
	public util = inject(UtilityService);
	public flyoutService = inject(FlyoutService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);


	public readonly survey = signal<{
		accSessionTopicWithSpecialists: AccSessionTopicWithSpecialists[],
		access: AccSessionSurveyAccess,
	} | undefined>(undefined);

	public hasAccess = computed(() => {
		const survey = this.survey();
		return !!survey && !!survey.access && !survey.access.accSessionSurveyResponseId && survey.access.attendee && survey.access.markedPresent && survey.access.surveyOpen;
	});


	public sameLanguage = computed(() => {
		const survey = this.survey();
		return !!survey && !!survey.access && survey.access.accLanguageId == this.urlService.languageId();
	});



	public showSurveyFields = computed<{ message: string, surveyFields: SurveyField[] } | undefined>(() => {
		const survey = this.survey();

		if (survey && this.hasAccess() && this.sameLanguage()) {

			let message = this.labels.MdSessionSurveyOpen() ?? '';
			const closeDate = this.util.date.formatUTC(survey.access.toolEndUTC, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.urlService.isSpanish() ? 'es-US' : 'en-US');

			message = message.split('{{close-date}}').join(closeDate);
			return {
				message,
				surveyFields: this.buildSurveyFields(survey),
			};
		}

		return undefined;

	});



	public message = computed<{ alertType: 'info' | 'success' | 'warning' | 'error', label: string } | undefined>(() => {

		const survey = this.survey();

		if (survey && survey.access) {
			const access = survey.access;
			const utc = Date.now() / 1000;


			// If survey survey already recorded
			if (access.accSessionSurveyResponseId) return {
				alertType: 'info',
				label: this.labels.SessionSurveyAlreadySubmitted()!,
			};


			//Survey hasn't begun yet
			if (utc < access.eventStartUTC) {
				let label = this.labels.MdSessionSurveyNotOpenYet() ?? '';
				const timePeriod = this.util.date.calculateTimePeriod(utc, access.toolStartUTC, this.urlService.isSpanish());
				label = label.split('{{time-period}}').join(timePeriod);
				label = label.split('{{pm-email}}').join(access.pmEmail);
				return {
					alertType: 'warning',
					label,
				};
			}


			//Passed Deadline
			if (utc > access.toolEndUTC) {
				let label = this.labels.MdSessionSurveyClosed() ?? '';
				const timePeriod = this.util.date.calculateTimePeriod(utc, access.toolStartUTC, this.urlService.isSpanish());
				label = label.split('{{time-period}}').join(timePeriod);
				label = label.split('{{pm-email}}').join(access.pmEmail);
				return {
					alertType: 'warning',
					label,
				};
			}


			if (!access.surveyOpen) {
				let label = this.labels.MdSessionSurveyNotOpenYet() ?? '';
				const timePeriod = this.util.date.calculateTimePeriod(utc, access.toolStartUTC, this.urlService.isSpanish());
				label = label.split('{{time-period}}').join(timePeriod);
				label = label.split('{{pm-email}}').join(access.pmEmail);
				return {
					alertType: 'warning',
					label,
				};
			}


			// No attendance taken yet || Not marked present
			if (!access.attendee || !access.markedPresent) {
				let label = this.labels.MdSessionSurveyClosed() ?? '';
				label = label.split('{{acc-name}}').join(access.accName);
				label = label.split('{{pm-email}}').join(access.pmEmail);

				return {
					alertType: 'info',
					label,
				};
			}

		}

		return undefined;
	});


	async ngOnInit() {
		const accSessionId = +this.route.snapshot.params['accSessionId'];
		this.frame.setUrlMetadata({
			url: `/surveys/acc-session/${accSessionId}`,
			backUrl: '/surveys',
			pageName: computed(() => `Survey`),
			headerText: computed(() => `Survey`),
		});

		const data = await this.func.surveys.accSession.getSessionTopicsWithSpecialists({ accSessionId });
		this.survey.set(data);
	}


	buildSurveyFields(survey: {
		accSessionTopicWithSpecialists: AccSessionTopicWithSpecialists[],
		access: AccSessionSurveyAccess,
	}) {

		const isSpanish = this.urlService.isSpanish();


		const surveyFields: SurveyField[] = [
			{
				headerLabel: this.labels.AcceleratorSession() + ` - ${this.util.date.formatUTC(survey.access.eventStartUTC, 'MMM D, YYYY (DOW)', 'H:MM AM EST', isSpanish ? 'es-US' : 'en-US')}`,
				id: survey.access.accSessionId,
				backgroundColor: '#eeeeee',
				iconClass: 'fa-screen-users',
				enabled: true,
				fields: [
					{
						questionLabel: this.labels.questions.IWasAbleToEasilyAttendThisSession() ?? '',
						key: `session-accessibility`,
						required: true,
						typeAndValue: {
							value: undefined,
							type: `Nps`
						},
					},
					{
						questionLabel: this.labels.questions.IReceivedAllInformationOfSession() ?? '',
						key: `session-communication`,
						required: true,
						typeAndValue: {
							value: undefined,
							type: `Nps`
						},
					},
					{
						questionLabel: this.labels.questions.TheSessionWasWellFacilitated() ?? '',
						key: `session-organization`,
						required: true,
						typeAndValue: {
							value: undefined,
							type: `Nps`
						},
					},
					{
						questionLabel: this.labels.questions.SessionLogisticsOptional() ?? '',
						key: `session-other`,
						required: false,
						typeAndValue: {
							value: '',
							type: `Paragraph`
						},
					},
				]
			}];

		for (const res of survey.accSessionTopicWithSpecialists) {
			let topic: SurveyField;
			if (res.topicTypeId != TopicTypeId.Teaching) {
				topic = {
					headerLabel: this.labels.common.Topic() + ` - ${this.labels.topicLongName[res.topicLongNameLabel]!() ?? ''}`,
					id: res.topicId,
					backgroundColor: '#fdc',
					iconClass: 'fa-square-t',
					yesOrNoQuestionLabel: survey.accSessionTopicWithSpecialists.length > 1 ? this.labels.questions.IWasPresentForThisTopic() : undefined,
					enabled: true,
					displayTextLabel: this.labels.questions.NoSurveyQuestionsMsg(),
					fields: [],
					subs: []
				};
			}
			else {
				topic = {
					headerLabel: this.labels.common.Topic() + ` - ${this.labels.topicLongName[res.topicLongNameLabel]!() ?? ''}`,
					id: res.topicId,
					backgroundColor: '#fdc',
					iconClass: 'fa-square-t',
					yesOrNoQuestionLabel: survey.accSessionTopicWithSpecialists.length > 1 ? this.labels.questions.IWasPresentForThisTopic() : undefined,
					enabled: true,
					fields: [
						{
							questionLabel: this.labels.questions.ThisTopicIsImportantForMyBusiness() ?? '',
							key: `topic-relevance`,
							required: true,
							typeAndValue: {
								value: undefined,
								type: `Nps`
							},
						},
						{
							questionLabel: this.labels.questions.IWillUseThisTopicNextThreeMonths() ?? '',
							key: `topic-need`,
							required: true,
							typeAndValue: {
								value: undefined,
								type: `Nps`
							},
						},
						{
							questionLabel: this.labels.questions.IHaveBetterUnderstandingOfTopic() ?? '',
							key: `topic-understanding`,
							required: true,
							typeAndValue: {
								value: undefined,
								type: `Nps`
							},
						},
						{
							questionLabel: this.labels.questions.ThereWasAdequateTimeDedicatedToTopic() ?? '',
							key: `topic-duration`,
							required: true,
							typeAndValue: {
								value: undefined,
								type: `Nps`
							},
						},
						{
							questionLabel: this.labels.questions.SayAboutTopicOptional() ?? '',
							key: `topic-other`,
							required: false,
							typeAndValue: {
								value: '',
								type: `Paragraph`
							},
						},
					],
					subs: [],
				};

				topic.subs = [];


				for (const specialist of res.specialists) {
					topic.subs.push({
						headerLabel: this.labels.common.Specialist() + ` - ${specialist.name}`,
						id: specialist.personId,
						backgroundColor: '#ecd1f0',
						iconClass: 'fa-person',
						yesOrNoQuestionLabel: res.specialists.length > 1 ? this.labels.questions.IWasPresentForThisSpecialist() : undefined,
						enabled: true,
						fields: [
							{
								questionLabel: this.labels.questions.TheSpecialistWasProfessional() ?? '',
								key: `specialist-professionalism`,
								required: true,
								typeAndValue: {
									value: undefined,
									type: `Nps`
								},
							},
							{
								questionLabel: this.labels.questions.TheSpecialistWasKnowledgeable() ?? '',
								key: `specialist-knowledge`,
								required: true,
								typeAndValue: {
									value: undefined,
									type: `Nps`
								},
							},
							{
								questionLabel: this.labels.questions.TheSpecialistEffectivelyTaughtTheTopic() ?? '',
								key: `specialist-effectiveness`,
								required: true,
								typeAndValue: {
									value: undefined,
									type: `Nps`
								},
							},
							{
								questionLabel: this.labels.questions.SayAboutSpecialistOptional() ?? '',
								key: `specialist-other`,
								required: false,
								typeAndValue: {
									value: '',
									type: `Paragraph`
								},
							},
						],
					});
				}

			}

			surveyFields.push(topic);

		}


		return surveyFields;

	}



	checkIfAllFieldsAreFilled(surveyFields: SurveyField[]) {

		let allFieldsFilled = true;

		for (const surveyField of surveyFields.filter(s => s.enabled)) {
			for (const field of surveyField.fields) {
				if ((field.typeAndValue.value == undefined || field.typeAndValue.value == null) && field.required) {
					allFieldsFilled = false;
					break;
				}
			}
			if (allFieldsFilled && surveyField.subs) {
				for (const sub of surveyField.subs.filter(s => s.enabled)) {
					for (const field of sub.fields) {
						if ((field.typeAndValue.value == undefined || field.typeAndValue.value == null) && field.required) {
							allFieldsFilled = false;
							break;
						}
					}
				}
			}
		}

		return allFieldsFilled;
	}


	async save() {
		const survey = this.survey();
		const surveyFields = this.showSurveyFields()?.surveyFields;

		if (surveyFields && surveyFields.length && survey) {

			const allFieldsAreFilled = this.checkIfAllFieldsAreFilled(surveyFields);

			if (!allFieldsAreFilled) {
				this.flyoutService.showMarkdownMessage(this.labels.SurveyAnswerAllQuestionsMsg() ?? '', this.labels.AccSessionSurvey() ?? '');
			}

			else if (allFieldsAreFilled && this.hasAccess()) {

				const response: AccSessionSurveyAnswers = {
					accSessionId: survey.access.accSessionId,
					sessionAnswers: surveyFields[0]!.fields.reduce((a: { 'accessibility': number, 'communication': number, 'organization': number, 'other': string }, field) => {
						const key = field.key.split('-')[1]!;
						if (key === 'accessibility') {
							a.accessibility = field.typeAndValue.value as number;
						}
						else if (key === 'communication') {
							a.communication = field.typeAndValue.value as number;
						}
						else if (key === 'organization') {
							a.organization = field.typeAndValue.value as number;
						}
						else if (key === 'other') {
							a.other = field.typeAndValue.value as string;
						}
						return a;
						// Setting to 0 cause we need an initial value and these will be swapped to use the user selected value as we do a check beforehand to see if all fields are filled
					}, { 'accessibility': 0, 'communication': 0, 'organization': 0, 'other': '' }),

					topicAnswers: surveyFields.slice(1)
						.filter(topic => topic.enabled)
						.reduce((a: AccSessionSurveyTopicAnswer[], topic) => {
							if (topic.fields.length) {

								const topicQuestion: {
									topicId: number, keyAndValue: { 'relevance': number, 'need': number, 'understanding': number, 'duration': number, 'other': string, },
									specialistAnswers: { personId: number, keyAndValue: { 'professionalism': number, 'knowledge': number, 'effectiveness': number, 'other': string } }[]
								} = {
									topicId: topic.id,
									keyAndValue: topic.fields
										.reduce((a: { 'relevance': number, 'need': number, 'understanding': number, 'duration': number, 'other': string, }, t) => {
											const key = t.key.split('-')[1]!;
											if (key === 'relevance') {
												a.relevance = t.typeAndValue.value as number;
											}
											else if (key === 'need') {
												a.need = t.typeAndValue.value as number;
											}
											else if (key === 'understanding') {
												a.understanding = t.typeAndValue.value as number;
											}
											else if (key === 'duration') {
												a.duration = t.typeAndValue.value as number;
											}
											else if (key === 'other') {
												a.other = t.typeAndValue.value as string;
											}
											return a;
											// Setting to 0 cause we need an initial value and these will be swapped to use the user selected value as we do a check beforehand to see if all fields are filled
										}, { 'relevance': 0, 'need': 0, 'understanding': 0, 'duration': 0, 'other': '' }),
									specialistAnswers: []
								};

								for (const specialist of (topic.subs ?? []).filter(sub => sub.enabled)) {
									const specialistQuestion = {
										personId: specialist.id,
										keyAndValue: specialist.fields
											.reduce((a: { 'professionalism': number, 'knowledge': number, 'effectiveness': number, 'other': string }, t) => {
												const key = t.key.split('-')[1]!;
												if (key === 'professionalism') {
													a.professionalism = t.typeAndValue.value as number;
												}
												else if (key === 'knowledge') {
													a.knowledge = t.typeAndValue.value as number;
												}
												else if (key === 'effectiveness') {
													a.effectiveness = t.typeAndValue.value as number;
												}
												else if (key === 'other') {
													a.other = t.typeAndValue.value as string;
												}
												return a;
												// Setting to 0 cause we need an initial value and these will be swapped to use the user selected value as we do a check beforehand to see if all fields are filled
											}, { 'professionalism': 0, 'knowledge': 0, 'effectiveness': 0, 'other': '' }),
									};
									topicQuestion.specialistAnswers.push(specialistQuestion);
								}

								a.push(topicQuestion);
							}
							return a;
						}, []),
				}

				this.survey.set(await this.func.surveys.accSession.setSessionSurveyResponse(response));
			}

		}
	}


	// async goToDashboard() {
	// 	await this.router.navigate(['access/my/dashboard']);
	// }

}
