import { Component, computed, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService, CommonButtonRowWidget, CommonIconTextWidget, UrlService } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService, MobileMenuItem } from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../app/app.service';
import { getLabels } from './user-menu-flyout.part.labels';


@Component({
	selector: 'public-user-menu-flyout-part',
	imports: [
		CommonIconTextWidget,
		CommonButtonRowWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		RouterModule,
	],
	templateUrl: './user-menu-flyout.part.html',
	styleUrl: './user-menu-flyout.part.scss'
})
export class PublicUserMenuFlyoutPart {
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	private app = inject(AppService);
	public frame = inject(MobileFrameService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);

	private auth = inject(AuthService);


	public menu = computed(() => {
		const user = this.app.data().user;
		const isSpanish = this.urlService.isSpanish();

		if (!user) return [];

		let menu: MobileMenuItem[] = [];

		const prospectMenu: MobileMenuItem[] = [
			{ label: this.labels.common.Applications(), routerLink: this.urlService.withCurrentLanguage('/applications'), iconStyle: 'fa-duotone', icon: 'fa-file-pen', },
			{ label: this.labels.common.Feedback(), routerLink: this.urlService.withCurrentLanguage('/feedback'), iconStyle: 'fa-duotone', icon: 'fa-message-smile', },
			{ label: this.labels.common.SignedAgreements(), routerLink: this.urlService.withCurrentLanguage('/agreements'), iconStyle: 'fa-duotone', icon: 'fa-file-signature', },
			{ label: this.labels.common.Profile(), routerLink: this.urlService.withCurrentLanguage('/profile'), iconStyle: 'fa-duotone', icon: 'fa-user', },
		];

		const cohortMenu: MobileMenuItem[] = [
			{ label: this.labels.common.Calendar(), routerLink: this.urlService.withCurrentLanguage('/calendar'), iconStyle: 'fa-duotone', icon: 'fa-calendars', },
			{ label: this.labels.common.Learning(), routerLink: this.urlService.withCurrentLanguage('/learning'), iconStyle: 'fa-duotone', icon: 'fa-books', },
			{ label: this.labels.common.Goals(), routerLink: this.urlService.withCurrentLanguage('/goals'), iconStyle: 'fa-duotone', icon: 'fa-chart-mixed', },
			{ label: this.labels.common.Surveys(), routerLink: this.urlService.withCurrentLanguage('/surveys'), iconStyle: 'fa-duotone', icon: 'fa-clipboard-list-check', },
			{ label: this.labels.common.Cohort(), routerLink: this.urlService.withCurrentLanguage('/cohort'), iconStyle: 'fa-duotone', icon: 'fa-people-group', },
			{ label: this.labels.common.MyTeam(), routerLink: this.urlService.withCurrentLanguage('/cohort/my-team'), iconStyle: 'fa-duotone', icon: 'fa-people', },
		];

		const alumMenu: MobileMenuItem[] = [
			{ label: this.labels.common.Volunteer(), url: isSpanish ? 'https://eforall.org/es/voluntariado/' : 'https://eforall.org/volunteering/', iconStyle: 'fa-duotone', icon: 'fa-handshake-angle', },
		];


		if (user.phase == 'Alum') {
			menu = [
				...alumMenu,
				...cohortMenu,
				...prospectMenu,
			];
		}
		else if (user.phase == 'Cohort') {
			menu = [
				...cohortMenu,
				...prospectMenu,
			];
		}
		else menu = prospectMenu;




		menu.push({ label: this.labels.common.EforAllWebsite(), url: isSpanish ? 'https://eforall.org/es' : 'https://eforall.org/', iconStyle: 'fa-duotone', icon: 'fa-globe-pointer', });

		// if (user.isTechAdmin) {
		menu.push({ label: 'Examples', routerLink: this.urlService.withCurrentLanguage('/examples'), iconStyle: 'fa-duotone', icon: 'fa-question', });
		// }

		return menu;
	});

	signOut() {
		this.auth.signOut();
		this.activeOffcanvas.dismiss();
	}

	toggleLanguage() {
		this.urlService.toggleLanguage();
		// this.activeOffcanvas.dismiss();
	}

	close() {
		this.activeOffcanvas.close();
	}
}