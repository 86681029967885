import { NgClass } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonButtonColumnWidget, CommonMessageWidget } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { ApplicationService } from './application.service';
import { ApplicationReadonlyPart } from './readonly/application-readonly.part';


@Component({
	selector: 'application-page',
	imports: [
		MobilePagePart,
		CommonButtonColumnWidget,
		CommonMessageWidget,
		ApplicationReadonlyPart,
		MobileListWidget,
		NgClass,
	],
	templateUrl: './application.page.html'
})

export class ApplicationPage implements OnInit {
	private frame = inject(MobileFrameService);
	public applicationService = inject(ApplicationService);
	private route = inject(ActivatedRoute);

	public instructions = computed(() => {
		const application = this.applicationService.application()?.application;
		const message = this.applicationService.labels.CongratulationsOnApplyingMessage()
			.split('{programName}').join(application?.programName)
			.split('{siteEmail}').join(application?.siteEmail)

		return message;
	});

	public tellUsAboutYou = computed<MobileListWithSubItem[]>(() => ([{
		icon: this.applicationService.aboutYouComplete() ? 'fa-circle-check color-green' : 'fa-circle color-lightgray',
		iconStyle: 'fa-solid',
		callback: () => {
			this.applicationService.navigateTo({ step: 'you' })
		},
		text: this.applicationService.labels.TellUsAboutYou()
	}]));


	public listItems = computed(() => {
		const items: MobileListWithSubItem[] = [];

		const tellUsAboutBusiness: MobileListWithSubItem = {
			icon: this.applicationService.aboutBusinessComplete() ? 'fa-circle-check color-green' : 'fa-circle color-lightgray',
			iconStyle: 'fa-solid',
			callback: () => {
				this.applicationService.navigateTo({ step: 'your-business' })
			},
			text: this.applicationService.labels.TellUsAboutYourBusiness()
		};


		const tellUsAboutTeam: MobileListWithSubItem = {
			icon: this.applicationService.aboutTeamComplete() ? 'fa-circle-check color-green' : 'fa-circle color-lightgray',
			iconStyle: 'fa-solid',
			callback: () => {
				this.applicationService.navigateTo({ step: 'your-team' })
			},
			text: this.applicationService.labels.TellUsAboutYourTeam(),
		};

		const applicationQuestions: MobileListWithSubItem = {
			icon: this.applicationService.answersComplete() ? 'fa-circle-check color-green' : 'fa-circle color-lightgray',
			iconStyle: 'fa-solid',
			text: this.applicationService.labels.ApplicationQuestions(),
			subItems: this.applicationService.answers().map((answer, idx) => {
				return {
					icon: answer.answer ? 'fa-circle-check color-green' : 'fa-circle color-lightgray',
					iconStyle: 'fa-solid',
					text: this.applicationService.labels.Question() + ` ${idx + 1}`,
					callback: () => {
						this.applicationService.navigateTo({ step: 'questions', questionId: answer.question.questionId });
					}
				};

			}),
		};

		items.push(tellUsAboutBusiness, ...this.tellUsAboutYou(), tellUsAboutTeam, applicationQuestions);

		return items;

	});



	public primaryBtn = computed<string>(() => {

		const application = this.applicationService.application();
		if (application && application.canSubmit && this.applicationService.stepsCompleted() === 4) {
			return 'submit';
		}

		const isClicked = this.instructionClicked();
		if (!isClicked) return 'instructions';
		return '';
	});

	instructionClicked = signal(false);


	constructor() {
		this.applicationService.setApplicationIdAndQuestion(+this.route.snapshot.params['applicationId'], undefined);
		this.frame.setUrlMetadata({
			url: `/applications/${this.applicationService.applicationId()}`,
			backUrl: '/applications',
			pageName: computed(() => this.applicationService.labels.Application()),
			headerText: computed(() => this.applicationService.labels.Application()),
		});
	}

	ngOnInit() {
		const applicationId = this.applicationService.applicationId();
		const key = `application-${applicationId}`;
		const isClicked = this.applicationService.getInstructionKey(key);
		this.instructionClicked.set(isClicked);
	}


	openInstructions() {
		const applicationId = this.applicationService.applicationId();
		if (applicationId) {
			const key = `application-${applicationId}`;

			const stored = this.applicationService.getInstructionKey(key);
			if (!stored) {
				this.applicationService.setInstructionKey(key, 'clicked');
				this.instructionClicked.set(true);
			}
			this.applicationService.openInstructionFlyout(this.instructions());
		}

	}

}