import { Component, computed, inject, OnInit, Signal, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonButtonRowWidget, CommonMessageWidget, CommonProgressBarWidget, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { CourseLevel } from '@interfaces';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { FlyoutService } from '../../../services';
import { LearningCourseService } from '../course.service';
import { getLabels } from './level.page.labels';

interface Child {
	name: string,
	type: string,
	description: string,
	subText: string,
	courseLevelStructureId: number,
	additionalDescription?: {
		description: string,
		levels: string[],
	},
	progress?: {
		text: string,
		percentage: number,
	}
}


interface LevelDetails {
	courseLevelStructureId: number,
	name: string, description: string, infoContent: Signal<string>, activities: MobileListWithSubItem[],
	childrenType?: string,
	children: Child[] | undefined,
	nextLevelUrl: string,
}


@Component({
	selector: 'level-page',
	imports: [
		MobilePagePart,
		CommonButtonRowWidget,
		RouterLink,
		MobileListWidget,
		CommonMessageWidget,
		NgbAccordionModule,
		CommonProgressBarWidget,
		CommonButtonRowWidget
	],
	templateUrl: './level.page.html'
})

export class LearningLevelPage implements OnInit {
	private frame = inject(MobileFrameService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);
	public courseService = inject(LearningCourseService);
	private flyoutService = inject(FlyoutService);
	private util = inject(UtilityService);
	public labels = getLabels(this.courseService.urlService);


	private readonly _courseLevelStructureId = signal<number | undefined>(undefined);
	public readonly courseLevelStructureId = this._courseLevelStructureId.asReadonly();


	public levelDetails = computed<LevelDetails | undefined>(() => {
		const isSpanish = this.courseService.urlService.isSpanish();
		const courseLevelStructureId = this.courseLevelStructureId();
		const course = this.courseService.course();

		if (course && courseLevelStructureId) {
			const { level, index, parents, nextLevelUrl } = this.findLevelAndIndex(course.levels, courseLevelStructureId);

			if (level) {
				return {
					courseLevelStructureId,
					name: `${this.labels[<'Module' | 'Section' | 'Topic'>level.levelType]()} ${index}: ${level.levelTitle}`,
					description: level.levelDescription,
					infoContent: computed(() => parents ?? ''),
					activities: level.activities.map((activity, idx) => {
						const url = `learning/courses/${course.courseVersionId}/activity/${activity.courseActivityStructureId}`;
						return {
							icon: activity.response && activity.response.completedUTC ? 'fa-circle color-green' : 'fa-circle color-lightgray',
							iconStyle: 'fa-solid',
							text: `${this.labels[<'Video' | 'Prompt' | 'Quiz' | 'Workbook' | 'Feedback'>activity.courseActivityType]()}: ${activity.activityTitle}`,
							subText: `${activity.response ?
								activity.response.completedUTC ? this.labels.Completed() : this.labels.Incomplete()
								: this.labels.NotStarted()} ${activity.activityOptional ? "(" + this.labels.Optional() + ")" : ""}`,
							callback: () => {
								const prevActivity = level.activities[idx - 1] ?? undefined;
								if (idx != 0 && prevActivity && !prevActivity.activityOptional && !prevActivity.response?.completedUTC) {
									this.flyoutService.showMarkdownMessage(this.labels.CompletePreviousActivityMsg(), this.labels.Message());
								}
								else this.router.navigate([this.courseService.urlService.withCurrentLanguage(url)]);
							}
						}
					}),
					childrenType: level.children.length ? this.labels[<'Module' | 'Section' | 'Topic'>level.children[0]!.levelType]() : undefined,
					children: this.buildChildren(level),
					nextLevelUrl,
				};
			}
		}

		return undefined;


	}, { equal: this.checkEquality })



	/**
 * Compare two auth state objects and determine if they are equivalent
 */
	private checkEquality(s1: LevelDetails | undefined, s2: LevelDetails | undefined) {

		if (s1?.courseLevelStructureId !== s2?.courseLevelStructureId) return false;

		return true;
	}


	async ngOnInit() {
		const courseVersionId = +this.route.snapshot.params['courseVersionId'];
		const courseLevelStructureId = +this.route.snapshot.params['courseLevelStructureId'];
		this._courseLevelStructureId.set(courseLevelStructureId);
		this.frame.setUrlMetadata({
			url: `/learning/courses/${courseVersionId}/level/${courseLevelStructureId}`,
			backUrl: `/learning/courses/${courseVersionId}`,
			pageName: computed(() => this.labels.Course()),
			headerText: computed(() => this.labels.Course()),
		});
		await this.courseService.loadDataAndSetCourseVersion(courseVersionId);
	}



	private findLevelAndIndex(levels: CourseLevel[], courseLevelStructureId: number): { level: CourseLevel | undefined; index: number; parents: string; nextLevelUrl: string } {
		let index = 0; // Initialize the level index for the outer levels

		for (const level of levels) {
			index++; // Increment the level index
			const levelType = this.labels[<'Module' | 'Section' | 'Topic'>level.levelType]();

			const parents = [`${levelType} ${index}`]; // Start with the current level's string

			// Check if the current level matches the courseLevelStructureId
			if (level.courseLevelStructureId === courseLevelStructureId) {
				// Find the siblingLevelId if available
				const siblingLevelId = levels[index] ? levels[index]!.courseLevelStructureId : undefined;
				const nextLevelUrl = siblingLevelId ? `../${siblingLevelId}` : `../..`;
				return { level, index, parents: parents.join(' > '), nextLevelUrl };
			}

			// Check direct children for a match
			let childIndex = 0; // Initialize index for children
			for (const child of level.children) {
				if (child.courseLevelStructureId === courseLevelStructureId) {
					const childLevelType = this.labels[<'Module' | 'Section' | 'Topic'>child.levelType]();

					parents.push(`${childLevelType} ${childIndex + 1}`); // Add the current child level type and index
					// Find the siblingLevelId for the child level if available
					const siblingLevelId = level.children[childIndex + 1] ? level.children[childIndex + 1]!.courseLevelStructureId : undefined;
					const nextLevelUrl = siblingLevelId ? `../${siblingLevelId}` : `../..`;
					return { level: child, index: childIndex + 1, parents: parents.join(' > '), nextLevelUrl };
				}

				// Check grand-children for a match
				let grandChildIndex = 0; // Initialize index for grandchildren
				for (const grandChild of child.children) {
					if (grandChild.courseLevelStructureId === courseLevelStructureId) {
						const childLevelType = this.labels[<'Module' | 'Section' | 'Topic'>child.levelType]();
						const grandchildLevelType = this.labels[<'Module' | 'Section' | 'Topic'>grandChild.levelType]();

						parents.push(`${childLevelType} ${childIndex + 1}`); // Add the current child level type and index
						parents.push(`${grandchildLevelType} ${grandChildIndex + 1}`); // Add the grandchild level type and index
						// Find the siblingLevelId for the grandchild level if available
						const siblingLevelId = child.children[grandChildIndex + 1] ? child.children[grandChildIndex + 1]!.courseLevelStructureId : undefined;
						const nextLevelUrl = siblingLevelId ? `../${siblingLevelId}` : `../..`;

						return { level: grandChild, index: grandChildIndex + 1, parents: parents.join(' > '), nextLevelUrl };
					}
					grandChildIndex++; // Increment grandchild index
				}
				childIndex++; // Increment child index
			}
		}

		return {
			level: undefined,
			index: -1,
			parents: '',
			nextLevelUrl: '../..',
		}; // Not found
	}



	private buildChildren(level: CourseLevel): Child[] | undefined {
		let children: Child[] | undefined = undefined
		if (!level.children.length) return children;

		children = level.children.map((child, idx) => {


			let subText = this.labels.NoActivities();
			let progress: {
				text: string,
				percentage: number,
			} | undefined = undefined;



			if (child.children.length) {
				subText = `${this.labels.Contains()} ${child.children.length} ${this.labels[<'Module' | 'Section' | 'Topic'>child.children[0]!.levelType]()}`;
			}
			else if (child.activities.length) {
				const completedActivities = child.activities.filter(activity => activity.response && activity.response.completedUTC).length;
				subText = `${completedActivities} ${this.labels.Of()} ${child.activities.length} ${this.labels.ActivitiesCompleted()}`;
			}


			if (!child.children.length && child.activities.length) {
				const activitiesCompleted = child.activities.filter(activity => activity.response && activity.response.completedUTC).length;
				progress = {
					percentage: Math.round((activitiesCompleted / child.activities.length) * 100),
					text: `${activitiesCompleted} ${this.labels.Of()} ${child.activities.length} ${this.labels.ActivitiesCompleted()}`
				};
			}


			const childLevelType = this.labels[<'Module' | 'Section' | 'Topic'>child.levelType]();
			const levels = child.children.map(child2 => {
				return child2.levelTitle;
			});

			
			const additionalDescription = child.children.length ? `${this.labels.This()} ${childLevelType.toLowerCase()} ${this.labels.Contains()} ${this.util.text.singularOrPluralWithCount(levels.length ?? 0, this.labels.Section().toLowerCase(), this.labels.Sections().toLowerCase())}`
				: undefined;

			return {
				name: `${childLevelType} ${idx + 1}: ${child.levelTitle}`,
				type: childLevelType,
				subText,
				progress,
				description: child.levelDescription,
				courseLevelStructureId: child.courseLevelStructureId,
				additionalDescription: child.children.length ? {
					description: additionalDescription!,
					levels,
				} : undefined,

			}
		});


		return children;
	}



	onNextClick() {

	}

}