import { Component, computed, inject, input, OnInit, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonSmileyRatingWidget, CommonTextFieldConfig, CommonTextFieldWidget, UrlService } from '@eforall/common';
import { CourseActivityFeedback } from '@interfaces';
import { getLabels } from './feedback.part.labels';


@Component({
	selector: 'learning-feedback-part',
	imports: [
		FormsModule,
		CommonSmileyRatingWidget,
		CommonTextFieldWidget,
	],
	templateUrl: './feedback.part.html'
})

export class LearningFeedbackPart implements OnInit {

	public urlService = inject(UrlService);
	public labels = getLabels(this.urlService);

	public readonly feedback = input<CourseActivityFeedback>();
	public readonly feedbackResponse = output<{
		feedbackRating: number,
		comments?: string
	}>();


	public response: {
		feedbackRating: number | undefined,
		comments?: string
	} = { feedbackRating: undefined, comments: undefined };


	readonly feedbackComment = computed(() => {
		const feedback = this.feedback();
		if (feedback && !feedback.completedUTC) {
			return {
				config: signal<CommonTextFieldConfig>({
					label: this.labels.AdditionalComments(),
					min: 0,
					max: 300,
					fixCase: true,
					multiLine: true,
					minHeight: 10,
					isSpanish: this.urlService.isSpanish()
				}),
				actualValue: computed(() => feedback?.comments || ''),
				pendingValue: signal<string | null>(null),
				saving: signal(false),
				save: async (value: string) => {
					this.response.comments = value ?? '';

					if (this.response.feedbackRating) this.feedbackResponse.emit({
						feedbackRating: this.response.feedbackRating,
						comments: this.response.comments,
					});
				},
				error: signal(''),
			};
		}

		return undefined;
	});


	ngOnInit() {
		const feedback = this.feedback();
		this.response = {
			feedbackRating: feedback?.feedbackRating,
			comments: feedback?.comments,
		};

	}



	public setRating(rating: number) {
		if (this.feedback()?.completedUTC) return;


		this.response.feedbackRating = rating;
		this.feedbackResponse.emit({
			feedbackRating: this.response.feedbackRating,
			comments: this.response.comments,
		});
	}
}