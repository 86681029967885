import { Component, inject } from '@angular/core';
import { UtilityService } from '@eforall/common';
import { MobileAvatarPart } from '../avatar/mobile-avatar.part';
import { MobileLogoPart } from '../logo/mobile-logo.part';
import { MobileFrameService } from '../mobile-frame.service';


@Component({
	selector: 'mobile-toolbar-part',
	imports: [
		MobileAvatarPart,
		MobileLogoPart,
	],
	templateUrl: './mobile-toolbar.part.html',
	styleUrls: ['./mobile-toolbar.part.scss']
})
export class MobileToolbarPart {

	public frame = inject(MobileFrameService);
	public util = inject(UtilityService);

	public canRequestFullscreen = this.util.fullscreen.getCanRequestSignal();
	public canExitFullscreen = this.util.fullscreen.getCanExitSignal();

	toggleMenu() {
		this.frame.config().openUserMenu();
	}
}