import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';


@Component({
	selector: 'common-message',
	imports: [CommonModule],
	templateUrl: './common-message.widget.html'
})
export class CommonMessageWidget {

	public alertType = input<'info' | 'success' | 'warning' | 'error'>('info');
	public closeable = input<boolean>(false);

	public closed = false;
}
