import { Component, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonDateTimeFieldWidget, CommonMultiselectFieldWidget, CommonSelectFieldWidget, CommonTextFieldWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { ProfileDemographicsFormService } from './demographics-form.service';


@Component({
	selector: 'profile-demographics-page',
	imports: [
		MobilePagePart,
		FormsModule,
		MarkdownModule,
		CommonSelectFieldWidget,
		CommonMultiselectFieldWidget,
		CommonTextFieldWidget,
		CommonDateTimeFieldWidget,
	],
	providers: [provideMarkdown()],
	templateUrl: './demographics.page.html'
})

export class ProfileDemographicsPage {

	private frame = inject(MobileFrameService);
	public readonly form = inject(ProfileDemographicsFormService);


	public readonly showOtherGender = computed(() => {
		if (this.form.gender().actualValue() === 'O') return true;
		else return false;
	});




	constructor() {
		this.frame.setUrlMetadata({
			url: '/profile/demographics',
			backUrl: '/profile',
			pageName: computed(() => this.form.labels.common.Demographics()),
			headerText: computed(() => this.form.labels.common.Demographics()),
		});
	}

}