<mobile-page-part>
	<div class="info-content">
		<h3>{{activityDetails()?.parents ?? labels.NoActivity()}}</h3>
	</div>

	@if(activityNotAvailable(); as activityNotAvailable){
	<markdown class="white-box" [data]="activityNotAvailable.message"></markdown>
	@if(activityNotAvailable.courseActivityStructureId){
	<common-button-row [center]="true">
		<button class="btn btn-large btn-primary"
			routerLink="../{{activityNotAvailable.courseActivityStructureId}}">{{labels.Resume()}}</button>
	</common-button-row>
	}
	}
	@else {
	@if(activityDetails(); as activityDetails){
	<div class="white-box">
		<h4>{{activityDetails.header}}</h4>

		<div class="d-flex flex-column gap-2">
			@if(activityDetails.title){
			<div>{{activityDetails.title}}
				@if(activityDetails.description && activityDetails.data!.type !== 'Feedback'){
				<button class="btn btn-flat btn-link"
					(click)="flyoutService.showMarkdownMessage(activityDetails.description, labels.Information())">
					{{ labels.MoreInfo() }}
				</button>
				}
			</div>
			}
			@if(activityDetails.description && activityDetails.data!.type === 'Feedback'){
			<div>{{activityDetails.description}}</div>
			}



			@if(activityDetails.data; as activity){
			@if(activity.type !== 'Video'){
			<hr class="my-2">
			}
			@if(activity.type === 'Feedback'){
			<learning-feedback-part [feedback]="activity.data"
				(feedbackResponse)="setFeedback($event)"></learning-feedback-part>
			}

			@if(activity.type === 'Workbook'){
			<learning-workbook-part [workbook]="activity.data"
				(workbookCompleted)="setWorkbookCompleted()"></learning-workbook-part>
			}

			@if(activity.type === 'Prompt'){
			<learning-prompt-part [prompt]="activity.data"
				(promptAnswered)="setPromptAnswer($event)"></learning-prompt-part>
			}

			@if(activity.type === 'Quiz'){
			<learning-quiz-part [quiz]="activity.data" (quizSubmitted)="submitQuiz($event)"
				(tryAgainClicked)="addAnotherQuizAttempt()"></learning-quiz-part>
			}

			}
		</div>

	</div>
	@if(activityDetails.data; as activityData){
	@if(activityData.type === 'Video'){
	<learning-video-part [video]="activityData.data" (setRating)="submitRating($event)"
		(videoWatchedVectorChanged)="updateVideo($event)"></learning-video-part>
	}
	}


	<common-button-row [center]="true">
		<button class="btn btn-standard" [routerLink]="previousActivityUrl()"><i
				class="fa-regular fa-chevron-left"></i></button>
		<button class="btn btn-primary btn-large" (click)="onNextClick()">{{labels.button.Next()}}</button>
	</common-button-row>


	}
	@else {
	<common-message alertType="warning">{{labels.NotEnrolledActivityMsg()}}</common-message>
	}
	}
</mobile-page-part>