import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { EnvironmentService } from '../../env';
import { CommonInternalSignedOutWidget } from './internal-signed-out.widget';


@Component({
	selector: 'common-signed-out',
	imports: [CommonModule, CommonInternalSignedOutWidget],
	templateUrl: './signed-out.widget.html'
})
export class CommonSignedOutWidget {

	public readonly env = inject(EnvironmentService);

	public readonly internalAuth = computed<boolean>(() => {

		const env = this.env.environment();
		return env?.authType === 'Internal';

	});

}
