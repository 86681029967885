import { Router } from '@angular/router';
import { Auth, User, getRedirectResult } from 'firebase/auth';
import { AnalyticsService } from '../analytics/analytics.service';
import { AngularEnvironment } from '../env';
import { UtilityService } from '../util';
import { AuthError, AuthState } from './auth-state';


/**
 * Return a function suitable for Firebase's onAuthStateChanged()
 */
export function getAuthStateChangedHandler(
	auth: Auth,
	analytics: AnalyticsService,
	util: UtilityService,
	router: Router,
	setState: (state: AuthState) => void,
	env: AngularEnvironment,
) {


	return async (user: User | null) => {

		util.log.info('onAuthStateChanged', { extra: user, extraLogging: 'ExtraToConsole' })

		//
		// Clear out any data stored on the router
		//
		router.resetConfig(router.config);

		if (!user) {

			// //
			// // Go back to the home page.
			// //
			// if (router.url !== '/') {
			// 	setTimeout(() => { router.navigate(['/'], { replaceUrl: true }) });
			// }


			//
			// We are now signed out.
			//
			setState({ status: 'signed-out' });

		}
		else {

			const redirect = await getRedirectResult(auth);
			const fromPersistence = redirect?.user == undefined;

			const email = user.email;

			if (!email) {
				await auth.signOut();
				setState({ status: 'error', error: AuthError.EmailNotVerified });
				throw new Error(`No Email Address`);	// TODO: communicate through ErrorService
			}
			else if (!user.emailVerified) {
				await auth.signOut();
				setState({ status: 'error', error: AuthError.EmailNotVerified });
				throw new Error(`${email} Not Verified!`); // TODO: communicate through ErrorService
			}
			else if (env.authType === 'Internal' && !email.endsWith('@eforall.org') && !email.endsWith('@bacancy.com')) {
				console.error(`ERROR: Internal auth signed in with ${email}.  Automatically signing out.`);
				await auth.signOut();
				setState({ status: 'signed-out' });
			}
			else {

				analytics.logLogin({ email, fromPersistence })

				setState({
					status: 'signed-in',
					user,
					loginType: fromPersistence ? 'from-persistence' : 'new',
				});
			}
		}
	}

}