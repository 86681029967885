import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CommonAddressFieldWidget, CommonButtonColumnWidget, CommonButtonRowWidget, CommonDateTimeFieldWidget, CommonMultiselectFieldWidget, CommonNumberFieldWidget, CommonPhoneFieldWidget, CommonSelectFieldWidget, CommonTextFieldWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { FuncService } from '../../../../services';
import { ApplicationJoinCodeFormService } from './join-code-form.service';
import { getLabels } from './join-code.page.labels';


@Component({
	selector: 'applications-join-code-page',
	imports: [
		MobilePagePart,
		CommonTextFieldWidget,
		CommonButtonRowWidget,
		CommonSelectFieldWidget,
		CommonNumberFieldWidget,
		CommonPhoneFieldWidget,
		CommonAddressFieldWidget,
		CommonMultiselectFieldWidget,
		CommonDateTimeFieldWidget,
		CommonButtonColumnWidget,
		RouterLink
	],
	templateUrl: './join-code.page.html'
})

export class ApplicationJoinCodePage implements OnInit {
	private frame = inject(MobileFrameService);
	private func = inject(FuncService);
	private route = inject(ActivatedRoute);


	public form = inject(ApplicationJoinCodeFormService);
	public labels = getLabels(this.form.urlService);


	public readonly code = signal('');
	public readonly codeDetails = signal<{ applicationId: number, programName: string, applicantName: string, companyName: string, applicationOpen: boolean } | undefined>(undefined);

	public join = false;

	public readonly showOtherGender = computed(() => {
		if (this.form.gender().actualValue() === 'O') return true;
		else return false;
	});



	async ngOnInit() {
		this.code.set(this.route.snapshot.params['applCode']);
		this.frame.setUrlMetadata({
			url: `/applications/join/${this.code()}`,
			backUrl: '/applications',
			pageName: computed(() => this.labels.Join()),
			headerText: computed(() => this.labels.Join()),
		});

		this.codeDetails.set(await this.func.pendingParticipant.validateInviteCode({ code: this.code().split('-').join('') }));

	}


}