import { Component, computed, inject } from '@angular/core';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { ApplicationApplyPart } from '../apply.part';
import { ApplicationApplyService } from '../apply.service';
import { getLabels } from './apply-accelerator.page.labels';


@Component({
	selector: 'applications-apply-accelerator-page',
	imports: [
		MobilePagePart,
		ApplicationApplyPart,
	],
	templateUrl: './apply-accelerator.page.html'
})

export class ApplicationApplyAcceleratorPage {
	private frame = inject(MobileFrameService);
	public applyService = inject(ApplicationApplyService);
	public labels = getLabels(this.applyService.urlService);

	public infoText = computed(() => this.labels.InfoMsg())

	constructor() {
		this.frame.setUrlMetadata({
			url: '/applications/apply-accelerator',
			backUrl: '/applications',
			pageName: computed(() => this.labels.Apply()),
			headerText: computed(() => this.labels.Apply()),
		});
	}

}