import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,
		
		AcceleratorCourse: computed(() => en() ? 'Accelerator Course' : 'Curso del acelerador'),
		Activity: computed(() => en() ? `Activity` : `Actividad`),
		Answer: computed(() => en() ? 'Answer (select one)' : 'Respuesta (seleccione una)'),
		Complete: computed(() => en() ? `Complete` : `Completado`),
		Course: computed(() => en() ? `Course` : `Curso`),
		CourseOverview: computed(() => en() ? 'Course Overview' : 'Descripción del Curso'),
		Due: computed(() => en() ? `Due` : `Vence`),
		DueSoon: computed(() => en() ? `Due Soon` : `Vencimiento pronto`),
		GetStarted: computed(() => en() ? `Get Started` : `Empezar`),
		Learning: computed(() => en() ? `Learning` : `Aprendizaje`),
		Materials: computed(() => en() ? `Materials` : `Materiales`),
		Module: computed(() => en() ? `Module` : `Módulo`),
		NoOngoingSession: computed(() => en() ? `No Ongoing Session` : `No hay sesión activa`),
		Of: computed(() => en() ? `of` : `de`),
		PastDue: computed(() => en() ? `Past Due` : `Vencido`),
		PickWhereLeftOff: computed(() => en() ? `Pick up where you left off` : `Reanudar donde se quedó`),
		Question: computed(() => en() ? 'Question' : 'Pregunta'),
		RequiredActivitiesCompleted: computed(() => en() ? `Required Activities Completed` : `Actividades requeridas completadas`),
		Resume: computed(() => en() ? `Resume` : `Reanudar`),
		Section: computed(() => en() ? `Section` : `Sección`),
		StartCourse: computed(() => en() ? `Start Course` : `Comenzar curso`),
		Topic: computed(() => en() ? `Topic` : `Tema`),
		TopicAvailable: computed(() => en() ? 'Topic Available' : 'Tema disponible'),
		TopicNoMaterialsMsg: computed(() => en() ? 'This topic does not have any course material.' : 'Este tema no tiene ningún material de curso.'),
		TopicsAvailable: computed(() => en() ? 'Topics Available' : 'Temas disponibles'),
		WelcomeMessageFromProgram: computed(() => en() ? `Welcome to EforAll. Our platform is here to support your business growth. Click 'Start Course' to begin your learning journey.` : `Te damos la bienvenida a EparaTodos. Nuestra plataforma está aquí para apoyar el crecimiento de tu negocio. Haz clic en 'Comenzar Curso' para comenzar tu camino de aprendizaje.`),
		ThankYouMessageFromProgram: computed(() => en() ? `Congratulations on completing the {course-title} Course. You may go back and review all content and materials at any time.` : `Felicitaciones por completar el curso {course-title}. Puedes volver a revisar todo el contenido y materiales en cualquier momento.`),
	};
}