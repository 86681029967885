
import { Component, OnInit, computed, inject, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilityService } from '../../../../services';
import { CommonFieldWidgetBase } from '../common-field-widget-base';
import { CommonSelectField } from '../field';


@Component({
	selector: 'common-select-field',
	imports: [FormsModule],
	templateUrl: './common-select-field.widget.html'
})
export class CommonSelectFieldWidget<T> extends CommonFieldWidgetBase<T, CommonSelectField<T>> implements OnInit {

	private util = inject(UtilityService);
	public readonly field = input.required<CommonSelectField<T>>();


	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: 'Campo requerido',
		} : {
			missingRequired: `Required field`,
		};

	});


	public readonly renderedModel = computed(() => {
		const value = this.field().pendingValue() ?? this.field().actualValue();
		return this.render(value);
	});



	ngOnInit() {
		this.field().error.set(this.validate());
	}

	public onChange(value: T) {
		this.field().pendingValue.set(value);
	}

	public async onBlur() {
		await this.blur(this.field());
	}


	protected validate(): string {

		const value = this.getValue(this.field());
		const config = this.field().config();
		const labels = this.labels();

		if (!value && config.required) return labels.missingRequired;

		return '';
	}
}